import useSocketConnection from "../../socket";
import {
  Modal,
  Button,
  Card,
  Col,
  Image,
  message,
  Row,
  Space,
  Spin,
  Typography,

} from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { useRef } from "react";
import { API, AVATAR_API } from "../../constants";
import "./Bestellingen.css";
import { useAuthContext } from "../../context/AuthContext";
import { getToken } from "../../helpers";
import { BEARER } from "../../constants";
import { CheckCircleTwoTone } from "@mui/icons-material";
import copy from "copy-to-clipboard";
import useProfileStats from "../../components/Level/levels";

const Bestellingen = ({ klaarBestellen }) => {
  const socket = useSocketConnection();
  const doc = new jsPDF();
  const tikkieURLRef = useRef();
  const [bestelling, setBestelling] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [falseBestellers, setFalseBestellers] = useState(true);
  const [profiles, setProfiles] = useState([]);
  const [alleBestellers, setAlleBestellers] = useState([]);
  const [bestellersNamen, setBestellersNamen] = useState([]);
  const [Bestellers, setBestellers] = useState([]);
  const { user, setUser } = useAuthContext();
  const [scriptcopied, setScriptCopied] = useState(false)
  const [guestOrders, setGuestOrders] = useState([]);

  const [moneyData, setMoneyData] = useState([]);
  const [basisscriptMiMi, setbasisscriptMiMi] = useState(`async function addToCartByProductNameWithExtras(productName, hasOptions, optionData, aantal) { const productNames = document.querySelectorAll('.ft-product-name'); for (const [index, nameElement] of Array.from(productNames).slice(1).entries()) { const name = nameElement.textContent.trim(); if (name === productName) { const addButton = nameElement.closest('.ft-product-row'); let quantity = 0; while(quantity < aantal){ await new Promise((resolve) => setTimeout(resolve, 1200)); if (addButton) { addButton.click(); const hasOptionsElement = document.querySelector('.btn-plus.ft_round_button.extras-popup.show-info-popup.ft_add_button'); const hasRadioButtonElement = document.querySelector('btn-plus ft_round_button extras-popup show-info-popup ft_add_button'); if (hasOptionsElement) { await new Promise((resolve) => setTimeout(resolve, 900)); if (hasOptions) { const checkboxes = document.querySelectorAll('.wcpa_checkbox input[type="checkbox"]'); checkboxes.forEach((checkbox) => { const checkboxLabel = checkbox.getAttribute('data-label'); if (optionData.includes(checkboxLabel)) { checkbox.checked = true; } }); const radioButtons = document.querySelectorAll('.wcpa_radio input[type="radio"]'); radioButtons.forEach((radioButton) => { const radioButtonLabel = radioButton.getAttribute('data-label'); if (optionData.includes(radioButtonLabel)) { radioButton.checked = true; } }); } const addToOrderButton = document.querySelector('.btn-add'); if (addToOrderButton) { addToOrderButton.click(); } }  }quantity = quantity + 1;} return; } } } async function checkPrice(totalpriceCheck) {
    const totalprice = document.querySelector(".ft-cart-price").textContent; 
    if (totalprice.indexOf(totalpriceCheck) >= 0) {
        alert("prijs klopt");
    } else {
       alert("prijs klopt niet");
    }
}`);
  const [uitlegScript, setUitlegScript] = useState("");
  const [checkBedragScript, setCheckBedragScript] = useState("");
  const [productenScript, setProductenScript] = useState("");
  const [volledigScript, setVolledigScript] = useState("");
  const delay = ms => new Promise(res => setTimeout(res, ms));
  const [orderDatum, setOrderDatum] = useState("");
  const [tikkieURL, setTikkieURL] = useState();
  const divRef = useRef(null);
  const { profilesStats, isLoadingStats, levelsStats, fetchLevelsAndProfiles } = useProfileStats();



  const fetchBestelling = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/orders?populate=*&filters[order_lists]`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${getToken()}`,
        }
      });
      const data = await response.json();
      console.log(data);
      const orders = data.data
      console.log(orders);
      setBestelling(orders ?? []);

      // console.log(data.data);
    } catch (error) {
      console.error(error);
      message.error("Error while fetching profiles!");
    } finally {
      setIsLoading(false);
      // console.log(bestelling);

    }
  };

  const fetchAlleBestellers = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/users?filters[meeBestellen][$eq]=true&populate=*`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${getToken()}`,
        }
      })
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setAlleBestellers(data ?? []);
      setBestellersNamen(data.id ?? []);
      // console.log(data);
      // console.log(data.id);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      // console.log(alleBestellers);
    }
  }

  const fetchBestellers = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/users?filters[klaarBestellen][$eq]=true`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${getToken()}`,
        }
      })
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setBestellers(data ?? []);
      // console.log(data);


    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      // console.log(Bestellers);
    }
  }

  const fetchProfile = async () => {
    setIsLoading(true);

    try {
      if (user) {
        const response = await fetch(`${API}/users/${user.id}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${getToken()}`,
          }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setProfiles(data ?? []);
        // console.log("Fetched Profile:", user);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);

    }
  };


  useEffect(() => {
    getToken();
    if (user) {
      fetchProfile();
      fetchBestelling();
      fetchBestellers();
      fetchAlleBestellers();
      setHidden();
    }
  }, [user]);

  const updateheleorder = async () => {

    try {
      // Fetch the list of orders first
      const datum = new Date().toISOString().split('T')[0];
      // console.log(datum);
      const ordersResponse = await fetch(`${API}/order-lists?filters[orderDate][$eq]=${datum}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${getToken()}`,
        }
      });
      if (!ordersResponse.ok) {
        console.error('Error fetching orders:', ordersResponse);
        message.error('Error fetching orders!');
        return;
      }

      const orderlistresponse = await ordersResponse.json();
      console.log(orderlistresponse);

      const maxId = orderlistresponse.data.reduce((max, order) => (order.id > max ? order.id : max), 0);

      // console.log('Highest ID:', maxId);

      const orders = bestelling.filter(item => item.huidigeOrder === true);
      // console.log(orders);

      const orderIds = orders.map(order => order.id);



      // Iterate through the orders and delete them one by one
      for (const order of orders) {
        const deleteResponse = await fetch(`${API}/orders/${order.id}`, {
          method: 'PUT',
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify({
            data:
            {
                order_lists: maxId,
            },
        },
        ),
        });

        if (deleteResponse.ok) {
          // console.log(`Order ${order.id} deleted successfully`);
        } else {
          console.error(`Error deleting order ${order.id}:`, deleteResponse);
          message.error(`Error deleting order ${order.id}!`);
        }
      }

      message.success('All orders deleted successfully!');
    } catch (error) {
      console.error(error);
      message.error('Network error while deleting orders!');
    } finally {
      fetchProfile();
      fetchBestelling();
      fetchBestellers();
      fetchAlleBestellers();
      setHidden();
    }
  };

  const sendToHomePage = async () => {
    setUnhidden();
    await delay(5000);
    window.location = '/spinapp';

    await delay(5000);
    // console.log("Waited an additional 5s");
  };


  const updateKans = async (IDUser, data) => { // Add async keyword here
    try {
      const response = await fetch(`${API}/users/${IDUser}`, { // Add await keyword here
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${getToken()}`,
        },
        body: JSON.stringify(data),
      });

      const responseData = await response.json(); // Add await keyword here

      if (response.ok) {
        // setUser(responseData);
        // fetchLevelsAndProfiles();
        message.success("Data saved successfully!");
        // console.log("updatakans");
      } else {
        console.error("Error updating user:", responseData);
        message.error("Error While Updating the Profile!");
      }
    } catch (error) {
      console.error(error);
      message.error("Network Error While Updating the Profile!");
    }
  };


  const updatePayments = async (naam) => {
    setIsLoading(true);
    try {
      // const response = await fetch(`${API}/orders?filters[users]=${naamID}&filters[huidigeOrder][$eq]=[true]`, {
      //   method: 'GET',
      //   headers: {
      //     'Authorization': `Bearer ${getToken()}`,
      //   }
      // });
      // const dataOrder = await response.json();
      // setMoneyData(dataOrder.data);
      // // console.log(dataOrder.data);
      
      // const finalpricepp = dataOrder.data.reduce((total, bestelling) => {
      //   return parseFloat(total) + parseFloat(bestelling.attributes.totaalprijs) * bestelling.attributes.quantity;
      // }, 0);
      console.log("HEUYYGY", naam, totalPricesPerPerson);
      console.log(totalPricesPerPerson[naam]);
      if (naam !== user.username) {
        try {
          const data = {
            'data': {
              naam: naam,
              naamOntvanger: user.username,
              teBetalen: totalPricesPerPerson[naam],
              betalerBetaald: false,
              ontvangerBevestigd: false,
              tikkieURL: tikkieURLRef.current,
            }
          };


          const response = await fetch(`${API}/payments`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${getToken()}`,
            },
            body: JSON.stringify(data),
          });

          const responseData = await response.json();

          if (response.ok) {
            message.success("payments updated successfully!");
            // console.log("payments");
            // console.log(moneyData);
          } else {
            console.error("Error updating payments:", responseData);
            message.error("Error While Updating the payments!");
          }
        } catch (error) {
          console.error(error);
          message.error("Network Error While Updating the payments!");
        }
      }

    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      // console.log(moneyData);

    }

  }

  const calculateTotalPrices = (orders) => {
    console.log(orders)
    const totalPricesPerPerson = {};

    orders.forEach((order) => {
      const totalPrice = parseFloat(order.attributes.totaalprijs) * order.attributes.quantity;
      // const username = order.users.username;
      const username = order.attributes.guestname;

      if (!totalPricesPerPerson[username]) {
        totalPricesPerPerson[username] = 0;
      }

      totalPricesPerPerson[username] += totalPrice;
    });

    return totalPricesPerPerson;
  };

  const totalPricesPerPerson = calculateTotalPrices(bestelling);

  // const consoleStats = async (naam, naamID) => {
  //   alleBestellers.forEach((namen) => {
  //     const totalPrice = totalPricesPerPerson[namen.username] || 0;

  //     console.log("stats", namen.username, namen.totaalOntvangen, totalPrice)
  //   })


  // }

  const sendStats = async (naam, naamID) => {
    alleBestellers.forEach(async (namen) => {
      const totalPrice = totalPricesPerPerson[namen.username] || 0;

      // console.log("stats", namen.username, namen.totaalOntvangen, totalPrice);

      const currentdate = new Date();
      const hours = currentdate.getHours().toString().padStart(2, '0');
      const minutes = currentdate.getMinutes().toString().padStart(2, '0');
      const seconds = currentdate.getSeconds().toString().padStart(2, '0');
      const milliseconds = currentdate.getMilliseconds().toString().padStart(3, '0');

      const data = {
        'data': {
          datum: currentdate,
          tijd: `${hours}:${minutes}:${seconds}:${milliseconds}`,
          totaalOntvangen: namen.totaalOntvangen,
          totaalUitgegeven: totalPrice,
          users: {
            id: namen.id  // Assuming userId is present in your alleBestellers data
          }
        }
      };

      try {
        const response = await fetch(`${API}/statistics`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`
          },
          body: JSON.stringify(data),
        });

        const responseData = await response.json();

        if (response.ok) {
          message.success("Statistics created successfully!");
          // console.log("1")
        } else {
          console.error("Error creating statistics:", responseData);
          message.error("Error While Creating Statistics!");
        }
      } catch (error) {
        console.error("Error in consoleStats:", error);
        message.error("An error occurred while creating statistics.");
      }
    });
  };

  // useEffect(() => {
  const updateList = async (orderItems) => {
    try {
      console.log("HELLO");
      const orderDate = new Date().toISOString().split('T')[0];
      setOrderDatum(new Date().toISOString().split('T')[0]);
      // console.log(bestelling);
      const totaalprijs = bestelling.reduce((total, namen) => total + parseFloat(namen.productprijs), 0);

      
      // console.log(orders);

      const orderIds = bestelling.map(order => order.id);

      console.log(orderIds);

      // const orderIds = orderItems.map(order => order.id);

      const data = {
        'data': {
          naam: `orderMiMi ${orderDate}`,
          orderDate: orderDate,
          users: {
            id: profiles.id
          },
          totaalprijs: finalprice,
          script: volledigScript,
          orders: orderIds,
        }
      };
      // console.log(profiles.username);
      // console.log(data);

      const response = await fetch(`${API}/order-lists`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${getToken()}`,
        },
        body: JSON.stringify(data),
      });

      const responseData = await response.json();

      if (response.ok) {
        message.success("Order list updated successfully!");
        // console.log("orderlist");
      } else {
        console.error("Error updating order list:", responseData);
        // message.error("Error While Updating the Order List!");
      }
    } catch (error) {
      console.error(error);
      // message.error("Network Error While Updating the Order List!");
    } finally {
      // updateheleorder();
      console.log("DONE?")
    }
  };

  function setHidden() {
    var x = document.getElementById("redirecting");
    x.style.display = "none";

  }

  function setUnhidden() {
    var x = document.getElementById("redirecting");
    if (x.style.display === "none") {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  }

  const handleTikkieChange = (e) => {
    const inputTikkie = e.target.value;
    setTikkieURL(inputTikkie);
    tikkieURLRef.current = inputTikkie;
};



  // }, []);
  function setReady() {
    if(volledigScript === ""){
      alert("je moet eerst het script kopiëren");
      return;
    }
    if (profiles.laatsteBestelling === false) {
      alert("je bent niet degene die moet bestellen");
      return;
    } else if (profiles.laatsteBestelling === true) {
      // console.log("YES");
    } else {
      // console.log("idk");
    }
    if (alleBestellers.length === Bestellers.length) {
      // console.log(totalPricesPerPerson)
      const username = user.username;
      Modal.confirm({
        title: 'Weet je zeker dat je alles hebt?',
        content: (
          <>
          <p>Vul Tikkie URL in:</p>
          <input onChange={handleTikkieChange} style={{ width: '100%' }} type="text" placeholder="www.tikkie.nl"/>
          </>
        ),
        onOk() {
          alleBestellers.forEach((namen) => {
            const totalPrice = totalPricesPerPerson[namen.username] || 0;
            // console.log(totalPricesPerPerson);
            const updateTotalPricePerPerson = namen.totaalUitgegeven + totalPrice
            const xpOfUitgegeven = totalPrice * 100;
            const xpUpdateOfUitgegeven = parseFloat(namen.xp) + xpOfUitgegeven;

            // console.log(namen.id)
            console.log(namen)
            updateKans(namen.id, {
              laatsteBestelling: false,
              meeBestellen: false,
              klaarBestellen: false,
              totaalUitgegeven: updateTotalPricePerPerson,
              xp: xpUpdateOfUitgegeven,

            }
            )
            // updatePayments(namen.username, namen.id);
            // console.log("stats", namen.username, namen.totaalOntvangen, totalPrice)

          }
          )
          // ABOVE WORKING
          const calculateTotalPersons = (orders) => {
            const uniqueUsernames = new Set();
          
            orders.forEach((order) => {
              // const username = order.users.username;
              console.log(order);
              const username = order.attributes.guestname;
              console.log(username);
              uniqueUsernames.add(username);
            });
          
            return Array.from(uniqueUsernames);
          };
          
          const uniqueUsernames = calculateTotalPersons(bestelling);
          console.log(uniqueUsernames);
          uniqueUsernames.forEach((namen) => {
            // updatePayments(namen.username, namen.id);
            console.log(namen);
            console.log(totalPricesPerPerson);
            updatePayments(namen);
          })
          // console.log(Array.isArray(groupedOfOrders));
          // console.log(groupedOfOrders)
          // console.log(Array.isArray(totalPricesPerPerson));
          // console.log(totalPricesPerPerson);
          // console.log(totalPricesPerPerson.length);
          // Object.values(totalPricesPerPerson).forEach((array) => {
          //   // console.log(array);
          //   // array.forEach((item) => {
          //   //   console.log(item);
          //   // });
          // });

          // console.log(bestellersNamen);
          updateList(); 
          // // updateheleorder();
          // // console.log("ja");
          // sendStats();

        },
        onCancel() {
          // console.log("hoi");
        },
      });
    } else {
      alert("NIET IEDEREEN HEEFT BESTELD");
    }

  }
  const finalprice = bestelling.reduce((total, bestelling) => {
    return parseFloat(total) + parseFloat(bestelling.attributes.totaalprijs) * bestelling.attributes.quantity;
  }, 0);


const getPDF = () => {
  const input = divRef.current;
  var doc = new jsPDF('p', 'pt', 'a4');
  var pageWidth = 595;
  var pageHeight = 842;
  var headerHeight = 15; // Header height in pts
  var footerHeight = 15; // Footer height in pts
  var contentHeight = input.clientHeight; // Height of the content without any padding
  var position = headerHeight; // Start position with header height

  const generatePDFPage = () => {
    const excludedElements = document.querySelectorAll('.excludeFromPDF');

    // Hide excluded elements during PDF generation
    excludedElements.forEach(element => {
      element.style.display = 'none';
    });

    html2canvas(input, {
      scale: 2,
      height: pageHeight - headerHeight - footerHeight,
      windowWidth: pageWidth,
      y: position,
      useCORS: true
    }).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      doc.addPage();
      doc.addImage(imgData, 'PNG', 0, headerHeight, pageWidth, pageHeight - headerHeight - footerHeight);
      position += pageHeight - headerHeight - footerHeight;
      if (position < contentHeight) {
        generatePDFPage();
      } else {
        doc.save('BestellingSnackManager.pdf');
      }

      // Show excluded elements after PDF generation
      excludedElements.forEach(element => {
        element.style.display = 'block';
      });
    });
  };

  generatePDFPage();
};

function convertToShortFormat(timeString) {
  const [hours, minutes] = timeString.split(':').slice(0, 2); 
  const shortFormat = `${hours}:${minutes}`;
  return shortFormat;
}

const originalTime = "16:00:00.000";
const convertedTime = convertToShortFormat(originalTime);

  // console.log(convertedTime); // Output: "16:00"


  function getScript() {
    if (scriptcopied === false) {
      if (profiles.laatsteBestelling === false) {
        alert("je bent niet degene die moet bestellen");
        return;
      } else if (profiles.laatsteBestelling === true) {
        // console.log("YES");
      } else {
        // console.log("idk");
      }
      if (alleBestellers.length === Bestellers.length) {
        setUitlegScript('copy paste het volgende in de console van MiMi: \n')
        // setbasisscriptMiMi(`async function addToCartByProductNameWithExtras(productName, hasOptions, optionData, aantal) { const productNames = document.querySelectorAll('.ft-product-name'); for (const [index, nameElement] of productNames.entries()) { const name = nameElement.textContent.trim(); if (name === productName) { const addButton = nameElement.closest('.ft-product-row'); let quantity = 0; while(quantity < aantal){ await new Promise((resolve) => setTimeout(resolve, 2000)); if (addButton) { addButton.click(); const hasOptionsElement = document.querySelector('.btn-plus.ft_round_button.extras-popup.show-info-popup.ft_add_button'); const hasRadioButtonElement = document.querySelector('btn-plus ft_round_button extras-popup show-info-popup ft_add_button'); if (hasOptionsElement) { await new Promise((resolve) => setTimeout(resolve, 1000)); if (hasOptions) { const checkboxes = document.querySelectorAll('.wcpa_checkbox input[type="checkbox"]'); checkboxes.forEach((checkbox) => { const checkboxLabel = checkbox.getAttribute('data-label'); if (optionData.includes(checkboxLabel)) { checkbox.checked = true; } }); const radioButtons = document.querySelectorAll('.wcpa_radio input[type="radio"]'); radioButtons.forEach((radioButton) => { const radioButtonLabel = radioButton.getAttribute('data-label'); if (optionData.includes(radioButtonLabel)) { radioButton.checked = true; } }); } const addToOrderButton = document.querySelector('.btn-add'); if (addToOrderButton) { addToOrderButton.click(); } }  }quantity = quantity + 1;} return; } } }`);
        setbasisscriptMiMi(`async function addToCartByProductNameWithExtras(productName, hasOptions, optionData, aantal) { const productNames = document.querySelectorAll('.ft-product-name'); for (const [index, nameElement] of Array.from(productNames).slice(1).entries()) { const name = nameElement.textContent.trim(); if (name === productName) { const addButton = nameElement.closest('.ft-product-row'); let quantity = 0; while(quantity < aantal){ await new Promise((resolve) => setTimeout(resolve, 1100)); if (addButton) { addButton.click(); const hasOptionsElement = document.querySelector('.btn-plus.ft_round_button.extras-popup.show-info-popup.ft_add_button'); const hasRadioButtonElement = document.querySelector('btn-plus ft_round_button extras-popup show-info-popup ft_add_button'); if (hasOptionsElement) { await new Promise((resolve) => setTimeout(resolve, 900)); if (hasOptions) { const checkboxes = document.querySelectorAll('.wcpa_checkbox input[type="checkbox"]'); checkboxes.forEach((checkbox) => { const checkboxLabel = checkbox.getAttribute('data-label'); if (optionData.includes(checkboxLabel)) { checkbox.checked = true; } }); const radioButtons = document.querySelectorAll('.wcpa_radio input[type="radio"]'); radioButtons.forEach((radioButton) => { const radioButtonLabel = radioButton.getAttribute('data-label'); if (optionData.includes(radioButtonLabel)) { radioButton.checked = true; } }); } const addToOrderButton = document.querySelector('.btn-add'); if (addToOrderButton) { addToOrderButton.click(); } }  }quantity = quantity + 1;} return; } } } async function checkPrice(totalpriceCheck) {
          const totalprice = document.querySelector(".ft-cart-price").textContent;
          if (totalprice.indexOf(totalpriceCheck) >= 0) {
              alert("prijs klopt");
          } else {
              alert("prijs klopt niet");
          }
      }`)
        
        let updatedProductenScript = productenScript;
        let wachttijd = 0;
        // let basis = "async function addToCartByProductNameWithExtras(productName, hasOptions, optionData, aantal) { const productNames = document.querySelectorAll('.ft-product-name'); for (const [index, nameElement] of productNames.entries()) { const name = nameElement.textContent.trim(); if (name === productName) { const addButton = nameElement.closest('.ft-product-row'); let quantity = 0; while(quantity < aantal){ await new Promise((resolve) => setTimeout(resolve, 2000)); if (addButton) { addButton.click(); const hasOptionsElement = document.querySelector('.btn-plus.ft_round_button.extras-popup.show-info-popup.ft_add_button'); const hasRadioButtonElement = document.querySelector('btn-plus ft_round_button extras-popup show-info-popup ft_add_button'); if (hasOptionsElement) { await new Promise((resolve) => setTimeout(resolve, 1000)); if (hasOptions) { const checkboxes = document.querySelectorAll('.wcpa_checkbox input[type='checkbox']'); checkboxes.forEach((checkbox) => { const checkboxLabel = checkbox.getAttribute('data-label'); if (optionData.includes(checkboxLabel)) { checkbox.checked = true; } }); const radioButtons = document.querySelectorAll('.wcpa_radio input[type='radio']'); radioButtons.forEach((radioButton) => { const radioButtonLabel = radioButton.getAttribute('data-label'); if (optionData.includes(radioButtonLabel)) { radioButton.checked = true; } }); } const addToOrderButton = document.querySelector('.btn-add'); if (addToOrderButton) { addToOrderButton.click(); } }  }quantity = quantity + 1;} return; } } }";

        bestelling.map((namen, index) => {
          if(namen.huidigeOrder === true){
if (namen.lijst === "") {
            updatedProductenScript += `
      setTimeout(() => {
        addToCartByProductNameWithExtras("${namen.productnaam}", false, [], ${namen.quantity});
      }, ${wachttijd});`
            wachttijd = wachttijd + namen.quantity * 4000;
          } else {
            const itemsWithPrices = namen.lijst.split('\n').map(item => item.trim());
            const itemNames = itemsWithPrices.map(item => item.split('€')[0].trim());
            updatedProductenScript += `
      setTimeout(() => {
        addToCartByProductNameWithExtras("${namen.productnaam}", true, ${JSON.stringify(itemNames)}, ${namen.quantity});
      }, ${wachttijd});`
            wachttijd = wachttijd + namen.quantity * 5000;
          }
          }
        });
        updatedProductenScript += `
        setTimeout(() => {
          checkPrice(${finalprice.toFixed(2)});
        }, ${wachttijd});`
        setProductenScript(updatedProductenScript);
        setVolledigScript(basisscriptMiMi + updatedProductenScript);
        let isCopy = copy(basisscriptMiMi + updatedProductenScript);

        //Dispalying notification
        if (isCopy) {
          setScriptCopied(true);
          message.success("Copied to Clipboard");
        }

      } else {
        alert("NIET IEDEREEN HEEFT BESTELD");
      }
    }
  }

  useEffect(() => {
    // Listen for socket events and handle them
    if (socket) {
      socket.on("connect", () => {
        // console.log("bestellingen on")
        socket.on("order:update", (data) => {
          fetchBestelling();
        });
        socket.on("order:create", (data) => {
          fetchBestelling();
        });
        socket.on("order:delete", (data) => {
          fetchBestelling();
        })
        socket.onAny((data) => {
          // console.log("socialcard test", data)
          fetchAlleBestellers();
        })
        socket.on("users-permissions:update", (user) => {
          try {
            // console.log("update user test", user);
            fetchBestellers();
          } catch (error) {
            console.error("Error in user:update event:", error);
          }
        });
        socket.on("disconnect", () => {
          // console.log("Socket disconnected");
        });
      });
    }
  }, [socket]);

  const groupedOrders = {};

  bestelling.forEach(order => {
    console.log(order)
    const { username } = order.attributes.users;
    if (!groupedOrders[username]) {
      groupedOrders[username] = [];
    }
    if(order.huidigeOrder === true){
      groupedOrders[username].push(order);
    }
    
  });

  const groupedOfOrders = {};

  bestelling.forEach(order => {
    console.log(order);
    const username = order.attributes.guestname;
    console.log(username);

    if (!groupedOfOrders[username]) {
      groupedOfOrders[username] = [];
    }

    if (order.attributes.huidigeOrder === true) {
      console.log(order);
      groupedOfOrders[username].push(order.attributes);
    }
  });



  return (
    <div ref={divRef} className="bestelling-container">
      <div className="divbestellers">
        <label className="labels">Wie bestellen er?</label>
        <ul>

          {(alleBestellers ?? []).map((namen) => (
            <li key={namen.id} className="namenbestelling">
              {namen.klaarBestellen ? <>&#9989;</> : null}
              {` ${namen.username}`}
              {namen.preferredOrderTime ? `, voorkeurstijd: ${convertToShortFormat(namen.preferredOrderTime)}` : null}
              
            </li>
          ))}
        </ul>
      </div>
      <label className="labels">Bestelling:</label>
      <br />
      <br />

      {Object.entries(groupedOfOrders).map(([username, orders]) => (
        <ul className="ulgeenstyle" key={username}>
          <li className="list3 top-even-row" key={username}>
            <span className="besteller">besteller: {username}</span>

            <ul>
              {orders.map((namen, index) => (
                <li className={`list3 ${index % 2 === 0 ? 'top-even-row' : 'top-odd-row'}`} key={namen.id}>
                  <input type="checkbox" className="bestellingenlist" key={namen.id} value={namen.productnaam} />
                  wie: {namen.guestname}<br></br>
                  Product: {namen.productnaam} <br />
                  Prijs: {namen.productprijs}<br />
                  Aantal: {namen.quantity}<br />


                  <span>
                    toevoeging: <br />
                    {namen.lijst.split('\n').map((item, index) => (
                      <p className="toevoeging" key={index}>{item.trim()}</p>
                    ))}
                  </span>
                </li>
              ))}
            </ul>
          </li>
        </ul>
      ))}
      
      <button
        id="updateButton"
        style={{ width: "20%", marginTop: "10px", height: "50px", marginLeft: "40%", }}
        className="btn btn-outline-secondary disableElement excludeFromPDF"
        onClick={getScript}
      >
        kopieer script
      </button>
      <button
        id="updateButton"
        style={{ width: "20%", marginTop: "10px", height: "50px", marginLeft: "40%", }}
        className="btn btn-outline-secondary disableElement excludeFromPDF"
        onClick={getPDF}
      >
        download PDF
      </button><button
        id="updateButton"
        style={{ width: "20%", marginTop: "10px", height: "50px", marginLeft: "40%", }}
        className="btn btn-outline-secondary disableElement excludeFromPDF"
        onClick={setReady}
      >
        klaar
      </button>
      <div className="bestellingen-totaalprijs">
        <ul>
          {Object.entries(totalPricesPerPerson).map(([username, totalPrice]) => (
            <li key={username} className="namenbestelling">
              Totaalprijs {username}: €{totalPrice.toFixed(2)}  
            </li>
          ))}
        </ul>

        Totaalprijs: €{finalprice.toFixed(2)}
      </div>
      <div className="script" id="script">
        {/* <div className="scripttext">{uitlegScript}<br />{basisscriptMiMi}<br />{productenScript}</div> */}
      </div>
      <div className="redirecting" id="redirecting">
        <div className="insideredirecting">data word opgeslagen, even geduld</div>
      </div>
    </div>
  );
}

export default Bestellingen;